
//@ts-ignore
import PrivatePOIsHeader from "@/views/Admin/Persons/V4/PrivatePOIs/PrivatePOIsHeader.vue";
import Vue from "vue";

export default Vue.extend({
    name: "PrivatePOISIndex",
    props: {},
    components: { PrivatePOIsHeader },
    data: () => ({}),
    created() {},
    async mounted() {},
    computed: {},
    methods: {},
});	
